// src/pages/Creations.js
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { db } from '../firebase'; // Ensure you have firebase initialized
import { collection, getDocs } from 'firebase/firestore';
import './Creations.css';

function Creations() {
  const [creations, setCreations] = useState([]);


  useEffect(() => {
    const fetchCreations = async () => {
      const querySnapshot = await getDocs(collection(db, 'creations'));
      const creationsData = [];
      querySnapshot.forEach((doc) => {
        creationsData.push(doc.data());
      });
      setCreations(creationsData);
    };

    fetchCreations();
  }, []);

  return (
    <main className="main-content">
      <h1>Creations</h1>
      {creations.map((c, index) => (
        <div key={index} className="creation-entry">
          {<ReactMarkdown>{c.content}</ReactMarkdown>}
        </div>
      ))}
    </main>
  );
}

export default Creations;
