function formatImperial(valueInMeters) {
    const totalInches = valueInMeters / 0.0254; // Convert meters to inches
    const feet = Math.floor(totalInches / 12); // Get the whole feet
    const inches = (totalInches % 12).toFixed(2); // Get the remaining inches
    return `${feet}' ${inches}"`; // Format as feet and inches
  }

  function unitToSymbol(unit) {
    switch (unit) {
      case "meters":
        return "m";
      case "centimeters":
        return "cm";
      case "feet":
        return "ft";
      case "inches":
        return "in";
      default:
        return "";
    }
  }

async function resizeImage(file) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve) => {
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const maxSize = 800; // Max width/height
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/png'); // Convert to base64
        resolve(dataUrl);
      };
    };
  });
}

export { formatImperial, unitToSymbol, resizeImage };
