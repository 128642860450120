import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import MarkdownEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { resizeImage } from '../components/tools/helpers';
import { db } from '../firebase'; // Ensure you have firebase initialized
import { collection, addDoc, getDocs, deleteDoc, doc, setDoc } from 'firebase/firestore';
import './Admin.css';

function Admin() {
  const [activeTab, setActiveTab] = useState('ateliers');
  const [calculations, setCalculations] = useState({});
  const [drafts, setDrafts] = useState([]);
  const [published, setPublished] = useState([]);
  const [currentDraft, setCurrentDraft] = useState(null);
  const [message, setMessage] = useState('');
  const small_max_vol = 50;
  const medium_max_vol = 200;
  const large_max_vol = 800;
  const cost_perstich = 0.33;
  const small_base_cost = 15;
  const medium_base_cost = 25;
  const large_base_cost = 40;
  const special_feature_cost = 5;

  const calculateBasket = () => {
    const width = document.querySelector('input[name="width"]').value;
    const height = document.querySelector('input[name="height"]').value;
    const depth = document.querySelector('input[name="depth"]').value;
    const stitches = document.querySelector('input[name="stitches"]').value;
    const volume = width * height * depth;
    let size_category = '';
    let base_price = 0;
    let stitch_price = 0;
    let special_feature_markup = 0;
    let total_price = 0;
    if (volume <= small_max_vol) {
      size_category = 'small';
      base_price = small_base_cost;
    } else if (volume <= medium_max_vol) {
      size_category = 'medium';
      base_price = medium_base_cost;
    } else {
      size_category = 'large';
      base_price = large_base_cost;
      setMessage('Draft saved successfully!');
      setTimeout(() => setMessage(''), 3000);
      setMessage('Draft saved successfully!');
      setTimeout(() => setMessage(''), 3000);
    }
    stitch_price = Math.ceil(stitches * cost_perstich);
    special_feature_markup = Math.ceil(document.querySelector('input[name="special_feature"]').value * special_feature_cost);
    total_price = Math.ceil(base_price + stitch_price+special_feature_markup);
    setCalculations({
      volume,
      size_category,
      base_price,
      stitch_price,
      special_feature_markup,
      total_price: Math.ceil(total_price)
    });
  };
  useEffect(() => {
    fetchDraftsAndPublished();
  }, []);

  const fetchDraftsAndPublished = async () => {
    const querySnapshot = await getDocs(collection(db, 'drafts'));
    const draftsData = [];
    querySnapshot.forEach((doc) => {
      draftsData.push({ id: doc.id, ...doc.data() });
    });
    const publishedSnapshot = await getDocs(collection(db, 'creations'));
    const publishedData = [];
    publishedSnapshot.forEach((doc) => {
      publishedData.push({ id: doc.id, ...doc.data() });
    });
    setDrafts(draftsData);
    setPublished(publishedData);
  };

  const handleDelete = async (id, type) => {
    const collectionName = type === 'draft' ? 'drafts' : 'creations';
    await deleteDoc(doc(db, collectionName, id));
    setMessage(`${type === 'draft' ? 'Draft' : 'Article'} deleted successfully!`);
    setTimeout(() => setMessage(''), 3000);
    fetchDraftsAndPublished();
  };

  const handleEdit = (item, type) => {
    setCurrentDraft({ ...item, type, title: item.title || '' });
    setEditorContent(item.content || '');
    setActiveTab('editor');
  };

  const handlePublish = async (draft) => {
    if (draft.id) {
      const docRef = doc(db, 'creations', draft.id);
      await setDoc(docRef, { title: draft.title, content: draft.content, createdAt: new Date() }, { merge: true });
      await handleDelete(draft.id, 'draft');
    } else {
      await addDoc(collection(db, 'creations'), {
        title: draft.title,
        content: draft.content,
        createdAt: new Date(),
      });
    }
    setMessage('Draft published successfully!');
    setTimeout(() => setMessage(''), 3000);
    fetchDraftsAndPublished();
  };

  const handleUnpublish = async (article) => {
    await addDoc(collection(db, 'drafts'), {
      title: article.title,
      content: article.content,
      createdAt: new Date(),
    });
    await handleDelete(article.id, 'creations');
    setMessage('Article unpublished successfully!');
    setTimeout(() => setMessage(''), 3000);
    fetchDraftsAndPublished();
  };

  const [editorContent, setEditorContent] = useState('');

  const handleEditorChange = ({ html, text }) => {
    setEditorContent(text);
  };

  const saveDraft = async (isPublish = false) => {
    if (!editorContent.trim()) return;

    try {
      if (currentDraft && currentDraft.id) {
        if (currentDraft && currentDraft.id) {
          const collectionName = isPublish ? 'creations' : 'drafts';
          const docRef = doc(db, collectionName, currentDraft.id);
          await setDoc(docRef, { title: currentDraft.title, content: editorContent }, { merge: true });
          if (!isPublish && currentDraft.type === 'creations') {
            await handleDelete(currentDraft.id, 'creations');
          } else if (isPublish && currentDraft.type === 'draft') {
            await handleDelete(currentDraft.id, 'draft');
          }
          setMessage(`${isPublish ? 'Published' : 'Draft'} saved successfully!`);
        } else {
          await addDoc(collection(db, 'drafts'), {
            title: currentDraft.title,
            content: editorContent,
            createdAt: new Date(),
          });
          setMessage('Draft saved successfully!');
        }
      } else {
        await addDoc(collection(db, 'drafts'), {
          title: currentDraft.title,
          content: editorContent,
          createdAt: new Date(),
        });
        setMessage('Draft saved successfully!');
      }
    } catch (error) {
      console.error("Error saving document: ", error);
      setMessage("Error saving document. Please try again.");
    }

    setMessage(`${isPublish ? 'Published' : 'Draft'} saved successfully!`);
    setTimeout(() => setMessage(''), 3000);
    fetchDraftsAndPublished();
    setCurrentDraft(null);
    setEditorContent('');
  };

  const renderContent = () => {
    if (activeTab === 'basket-calc') {
      return (
        <>
          <h2>Calculateur de paniers</h2>
          <div className="form">
            <p>Utilisez cet outil pour calculer le prix d'un panier</p>
            <label>Largeur: <input type="number" name="width" /></label>
            <label>Hauteur: <input type="number" name="height" /></label>
            <label>Profondeur: <input type="number" name="depth" /></label>
            <label>Points: <input type="number" name="stitches" /></label>
            <label>Option spéciale: <input type="number" name="special_feature" /></label>
            <p>Résultats:</p>
            <p>Volume: {calculations.volume}</p>
            <p>Catégorie: {calculations.size_category}</p>
            <p>Prix de base: {calculations.base_price}</p>
            <p>Prix des points: {calculations.stitch_price}</p>
            <p>Option spéciale: {calculations.special_feature_markup}</p>
            <p>Prix total: {calculations.total_price}</p>
            <button onClick={() => calculateBasket()}>Calculer</button>
          </div>
        </>
      );
    }
    if (activeTab === 'editor') {
      return (
        <div className="creations-list">
          <button className="newdraftButton" onClick={() => handleEdit({ content: '', title: '' }, 'draft')}>+ New</button>
          <div className="drafts-section article-section">
            <h3>Drafts</h3>
            <ul className='drafts-list article-list'>
              {drafts.map((draft) => (
                <li key={draft.id}>
                  <strong>{draft.title}</strong>
                  <button onClick={() => handleEdit(draft, 'draft')}>✎</button>
                  <button onClick={() => handlePublish(draft)}>✔</button>
                  <button onClick={() => handleDelete(draft.id, 'draft')}>✖</button>
                </li>
              ))}
            </ul>
            {drafts.length === 0 && <p>No drafts available</p>}
          </div>
          <div className="published-section article-section">
            <h3>Published Articles</h3>
            <ul className='pubs-list article-list'>
              {published.map((article) => (
                <li key={article.id}>
                  <strong>{article.title}</strong>
                  <button onClick={() => handleEdit(article, 'creations')}>✎</button>
                  <button onClick={() => handleUnpublish(article)}>⯃</button>
                  <button onClick={() => handleDelete(article.id, 'creations')}>✖</button>
                </li>
              ))}
            </ul>
            {published.length === 0 && <p>No articles available</p>}
          </div>
          {currentDraft && (
            <div className="editor-modal-overlay">
              <div className="editor-modal">
                <h2>Editing: {currentDraft.type === 'draft' ? 'Draft' : 'Published Article'}</h2>
                <input
                  type="text"
                  placeholder="Title"
                  value={currentDraft.title || ''}
                  onChange={(e) => setCurrentDraft({ ...currentDraft, title: e.target.value })}
                />
                <MarkdownEditor
                  style={{ height: '500px' }}
                  value={editorContent}
                  renderHTML={(text) => <ReactMarkdown>{text}</ReactMarkdown>}
                  onImageUpload={async (file) => {
                    const resizedImage = await resizeImage(file);
                    return Promise.resolve(resizedImage);
                  }}
                  onChange={handleEditorChange}
                />
                <button onClick={() => { saveDraft(); setCurrentDraft(null); }}>Save Draft</button>
                <button onClick={() => { saveDraft(true); setCurrentDraft(null); }}>Publish</button>
                <button onClick={() => setCurrentDraft(null)}>Cancel</button>
                {currentDraft && (
                  <button onClick={() => handleDelete(currentDraft.id, currentDraft.type)}>Delete</button>
                )}
              </div>
            </div>
          )}
        </div>
      );
     
    }
  };

  return (
    <div id="admin">
      <h1>BACKROOM</h1>
      {message && <p className="feedback-message">{message}</p>}

      <div className="admin-tabs">
        <button className={`${activeTab == 'basket-calc' ? 'active' : '' }`} onClick={() => setActiveTab('basket-calc')}>Calc. Paniers</button>
        <button className={`${activeTab == 'editor' ? 'active' : ''}`} onClick={() => setActiveTab('editor')}>Creations</button>
      </div>
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
}

export default Admin;
