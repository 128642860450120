// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Ateliers from './pages/Ateliers';
import Creations from './pages/Creations';
import Legal from './pages/Legal';
import Contact from './pages/Contact';
// import WhoAmI from './components/WhoAmI';
import './App.css';
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Admin from './pages/Admin';
import Outils from './pages/Outils';
import bg from './assets/bg.png'; // Place your background image in this path


function App() {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, [auth]);

  return (
    <div>
    <div id="app" style={{backgroundImage:`url(${bg})`}}>
      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ateliers" element={<Ateliers />} />
          <Route path="/creations" element={<Creations />} />
          <Route path="/outils" element={<Outils />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/admin" element={ user ? <Admin /> : <></>} />
        </Routes>
        <Footer />
      </Router>
    </div>
  </div>
  );
}

export default App;
