import React from 'react';
import { useState } from 'react';
import './Newsletter.css';

function Newsletter({ text }) {
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    const handleIframeLoad = () => {
        setIsIframeLoaded(true);
    };
    return (
        <div className={`newsletter-container ${isIframeLoaded ? 'loaded' : '' }`}>
        <p>{text}</p>
        <iframe
    className='newsletter'
    title="Newsletter"
    onLoad={handleIframeLoad}
    style={{ border: 'none', width: '100%', height: '100%',background: 'white' }}
src="https://buttondown.com/corde-ecorce?as_embed=true"
></iframe>
</div>
    )
}

export default Newsletter;