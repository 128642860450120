// src/pages/Ateliers.js
import React from 'react';
import './Ateliers.css';
import turktools from '../assets/turktools.png'; // Place your background image in this path
import Newsletter from '../components/Newsletter';
function Ateliers() {
  return (
    <main className="main-content">
      <h1>Ateliers</h1>
      <div className="flex-content">
      <p>
      Je prépare une série d’ateliers pour 2025, centrés sur des savoir-faire comme le feu primitif, les nœuds traditionnels, les bonnet turcs ou encore la fabrication de corde. Si ces thèmes vous intéressent ou si vous souhaitez discuter d’un projet ou d’une collaboration,  <a href="/contact">contactez-moi</a> dès maintenant.
      </p>
      <Newsletter text="Envie de recevoir des nouvelles de mes ateliers et de mes projets? Abonnez-vous a mon infolettre!" />
      <img  src={turktools} alt="Outils pour bonnets turcs."  />
      </div>
     </main>
  );
}

export default Ateliers;
